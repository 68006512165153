<div class="mb-3" style="display: flex; justify-content: space-between">
  <h4>Anomalies with status: {{ title }}</h4>
  <button class="btn buttonSearch" (click)="onGoBack.emit()">Back</button>
</div>
<!-- <p>Total in progress:</p> -->
<table class="table table-striped planrequestInfo">
  <thead>
    <tr class="headerTable">
      <th sortable="planRequestId" (sort)="onSort($event)">Planrequest Id</th>
      <th sortable="reason" (sort)="onSort($event)">Reason</th>
      <th sortable="status" (sort)="onSort($event)">Stage</th>
      <th sortable="createdAt" (sort)="onSort($event)">Created</th>
      <th sortable="modifiedAt" (sort)="onSort($event)">Last modified</th>
    </tr>
  </thead>
  <tbody *ngIf="planRequestAnomalies.length > 0">
    <tr
      *ngFor="let anomaly of planRequestAnomaliesPaged"
      (click)="toDetails(anomaly.planRequestId)"
    >
      <td class="planreq-id">{{ anomaly.planRequestId }}</td>
      <td>{{ enums.enumAnomalyReason.get(anomaly.reason) }}</td>
      <td>{{ getPlanRequestStageText(anomaly.status) }}</td>
      <td>{{ anomaly.createdAt | date: "short" }}</td>
      <td>{{ anomaly.modifiedAt | date: "short" }}</td>
    </tr>
  </tbody>
  <tbody *ngIf="planRequestAnomalies.length === 0">
    <tr class="text-center">
      <td colspan="100%">No anomalies with this planrequest stage.</td>
    </tr>
  </tbody>
</table>

<div class="d-flex justify-content-between p-2">
  <ngb-pagination
    [collectionSize]="collectionSize"
    [(page)]="page"
    [maxSize]="5"
    [pageSize]="pageSize"
    (pageChange)="refresh()"
    [boundaryLinks]="true"
  >
    <ng-template ngbPaginationPages>
      <li class="ngb-custom-pages-item" *ngIf="pages > 0">
        <div class="form-group d-flex flex-nowrap px-2">
          <label
            id="paginationInputLabel"
            for="paginationInput"
            class="col-form-label mr-2 ml-1"
            >Page</label
          >
          <input
            #i
            type="text"
            inputmode="numeric"
            pattern="[0-9]*"
            class="form-control custom-pages-input"
            id="paginationInput"
            [value]="page"
            (keyup.enter)="selectPage(i.value)"
            (blur)="selectPage(i.value)"
            (input)="formatInput($any($event).target)"
            aria-labelledby="paginationInputLabel paginationDescription"
            style="width: 3rem"
          />
          <span
            id="paginationDescription"
            class="col-form-label text-nowrap px-2"
          >
            of {{ pages }}</span
          >
        </div>
      </li>
    </ng-template>
  </ngb-pagination>
  <select
    class="custom-select"
    style="width: auto"
    [(ngModel)]="pageSize"
    (ngModelChange)="refresh()"
  >
    <option [ngValue]="4">4 anomalies per page</option>
    <option [ngValue]="6">6 anomalies per page</option>
    <option [ngValue]="8">8 anomalies per page</option>
    <option [ngValue]="10">10 anomalies per page</option>
    <option [ngValue]="collectionSize">All anomalies</option>
  </select>
</div>
