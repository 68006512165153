import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SharedService } from '../shared/shared.service';
import { ActiveServices } from '../_models/activeservices.model';
import { AttachmentMaintenance } from '../_models/attachmentMaintenance.model';
import { InProgress } from '../_models/inProgress.model';
import { PlanRequestAnomaly } from '../_models/planrequest.model';
import { SendGridMailCount } from '../_models/sendgridmailcount';
import { ActiveWorkFlow } from '../_models/workflow.model';

@Injectable({
  providedIn: 'root',
})
export class MonitoringService {
  //from anomaly overview to anomaly details
  onToDetails: EventEmitter<string> = new EventEmitter();

  urlPart: string = '/Monitoring';

  constructor(private http: HttpClient) {}

  checkActiveServices() {
    return this.http
      .get<ActiveServices>(
        `${environment.fmbackendurl}${this.urlPart}/activeservices`
      )
      .pipe(
        catchError(
          SharedService.handleError<ActiveServices>('checkActiveServices')
        )
      );
  }

  checkSendGridMailCount() {
    return this.http
      .get<SendGridMailCount[]>(
        `${environment.fmbackendurl}${this.urlPart}/sendgridmailcount`
      )
      .pipe(
        catchError(
          SharedService.handleError<SendGridMailCount[]>(
            'checkSendGridMailCount'
          )
        )
      );
  }

  getActiveWorkFlow(planRequestId: string) {
    return this.http
      .get<ActiveWorkFlow>(
        `${environment.fmbackendurl}${this.urlPart}/activeworkflows?id=${planRequestId}`
      )
      .pipe(
        catchError(
          SharedService.handleError<ActiveWorkFlow>('getPlanRequestWorkFlow')
        )
      );
  }

  getPlanRequestAnomalies() {
    return this.http
      .get<PlanRequestAnomaly[]>(
        `${environment.fmbackendurl}${this.urlPart}/planrequestanomalies`
      )
      .pipe(catchError(SharedService.handleError<PlanRequestAnomaly[]>()));
  }

  getPlanRequestsInProgress() {
    return this.http
      .get<InProgress>(`${environment.fmbackendurl}${this.urlPart}/inprogress`)
      .pipe(catchError(SharedService.handleError<InProgress>()));
  }

  getAttachmentMaintenance() {
    return this.http
      .get<AttachmentMaintenance[] | null>(
        `${environment.fmbackendurl}${this.urlPart}/attachmentmaintenance`
      )
      .pipe(catchError(SharedService.handleError<AttachmentMaintenance[] | null>()));
  }

  getLastAnomalyCalculation(){
    return this.http
      .get<string>(
        `${environment.fmbackendurl}${this.urlPart}/lastanomalycalculation`
      )
      .pipe(
        catchError(
          SharedService.handleError<string>('getLastAnomalyCalculation')
        )
      );
  }
}
