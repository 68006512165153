import { Injectable, OnInit } from '@angular/core';
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SignalrService {
  connection: HubConnection;
  signalRSubject$ = new Subject<void>();

  constructor() { }

  startConnection() {
    //temp: from https://docs.microsoft.com/en-us/aspnet/core/signalr/javascript-client?view=aspnetcore-5.0#connect-to-a-hub
    this.connection = new HubConnectionBuilder()
      .withUrl(`${environment.fmbackendurl}/api/socket/anomaly`)
      .configureLogging(LogLevel.Information)
      .build();

    this.connection.on('anomalyMessage', (data: string) => {
      if (data === 'GetAnomaly') {
        this.signalRSubject$.next();
      }
    })

    this.connection.start()
      .then(() => console.log('SignalR connection started'))
      .catch(err => console.log('Error while starting connection: ' + err))
  }

  ngOnDestroy() {
    this.connection.onclose(() => {
      console.log('SignalR connection closed');
    })
    this.connection.stop();
  }
}
