import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { PieChartModel } from 'src/app/_models/chart.model';
import { PlanRequestStage } from 'src/app/_models/enums.model';
import {
  PlanRequestAnomaly,
  PlanRequestAnomalyChartData,
} from 'src/app/_models/planrequest.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss'],
})
export class ChartsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isCollapsed: boolean;
  @Input() activeIdChangeObs: Subject<number>;
  @Input() planrequestAnomalies: PlanRequestAnomaly[];
  @Input() totalInProgress: number;
  @Input() tabId: number;
  @Input() activeId: number;
  dataRefreshed: boolean = true;

  totalAnomalies: number;

  warningLimit = environment.chartWarningPercentageLimit;

  activeIdSubscription: Subscription;
  timerSubscription: Subscription;

  detailsTitle: string;
  detailsStatus: PlanRequestAnomaly[] | undefined;
  planRequestAnomaliesChartData: PlanRequestAnomalyChartData;

  constructor() {}

  ngOnInit(): void {
    this.activeIdSubscription = this.activeIdChangeObs.subscribe((id) => {
      if (id === this.tabId && this.dataRefreshed) {
        this.updateCharts();
        this.dataRefreshed = false;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.planrequestAnomalies &&
      changes.planrequestAnomalies.currentValue
    ) {
      this.updateCharts();
      this.dataRefreshed = this.activeId !== this.tabId;
    }

    if (
      changes.totalInProgress &&
      changes.totalInProgress.currentValue
    ) {
      this.updateCharts;
      this.dataRefreshed = this.activeId !== this.tabId;
    }
  }

  ngOnDestroy() {
    this.activeIdSubscription.unsubscribe();
  }

  updateCharts() {
    this.resetCharts();

    this.totalAnomalies = this.planrequestAnomalies.length; //todo:


    this.planrequestAnomalies.forEach((anomaly) => {
      let status = anomaly.status;
      if (status & PlanRequestStage.PlanRequestCreated)
        this.planRequestAnomaliesChartData.categories[0].anomalies.push(
          anomaly
        );
      if (status & PlanRequestStage.PlanRequestPublished)
        this.planRequestAnomaliesChartData.categories[1].anomalies.push(
          anomaly
        );
      if (status & PlanRequestStage.EmailSendToPlanRequestor)
        this.planRequestAnomaliesChartData.categories[2].anomalies.push(
          anomaly
        );
      if (status & PlanRequestStage.EmailSendToConcernedKlimAdmin)
        this.planRequestAnomaliesChartData.categories[3].anomalies.push(
          anomaly
        );
      if (status & PlanRequestStage.SendReminderEmail)
        this.planRequestAnomaliesChartData.categories[4].anomalies.push(
          anomaly
        );
      if (status & PlanRequestStage.PlanRequestConfirmed)
        this.planRequestAnomaliesChartData.categories[5].anomalies.push(
          anomaly
        );
      if (this.tabId === 2 && status & PlanRequestStage.SendConfirmationKlip)
        this.planRequestAnomaliesChartData.categories[6].anomalies.push(
          anomaly
        );
      if (status & PlanRequestStage.UploadPlansToKPL) {
        let i = this.tabId === 2 ? 7 : 6;
        this.planRequestAnomaliesChartData.categories[i].anomalies.push(
          anomaly
        );
      }
      if (status & PlanRequestStage.BulkZipGenerated) {
        let i = this.tabId === 2 ? 8 : 7;
        this.planRequestAnomaliesChartData.categories[i].anomalies.push(
          anomaly
        );
      }
      if (status & PlanRequestStage.EmailSendToPlanRequestorWithPlans) {
        let i = this.tabId === 2 ? 9 : 8;
        this.planRequestAnomaliesChartData.categories[i].anomalies.push(
          anomaly
        );
      }
      this.planRequestAnomaliesChartData.totalAnomalies++;
    });



    for (let i in this.planRequestAnomaliesChartData.categories) {
      this.planRequestAnomaliesChartData.categories[i].chart.title =
        this.planRequestAnomaliesChartData.categories[i].name;

        //todo:
      let totalAnoms = this.totalInProgress -
        this.planRequestAnomaliesChartData.categories[i].anomalies.length;
      if(totalAnoms === 0) totalAnoms = 1;
      if(totalAnoms < 0) totalAnoms = 0;
      this.planRequestAnomaliesChartData.categories[i].chart.data = [
        {
          name: 'Total anomalies',
          value: totalAnoms//this.totalAnomalies - this.planRequestAnomaliesChartData.categories[i].anomalies.length,
        },
        {
          name: 'This category',
          value:
            this.planRequestAnomaliesChartData.categories[i].anomalies.length,
        },
      ];
      //todo:
      let percentage = this.round(
        (this.planRequestAnomaliesChartData.categories[i].anomalies
          .length /
          this.totalInProgress) *
          100
      );
      if(percentage === Infinity) percentage = 100;
      if(Number.isNaN(percentage)) percentage = 0;
      this.planRequestAnomaliesChartData.categories[i].chart.percentage = percentage;

      //red or orange based on amount of anomalies
      this.planRequestAnomaliesChartData.categories[
        i
      ].chart.colorScheme.domain[1] =
        this.planRequestAnomaliesChartData.categories[i].chart.percentage <
        this.warningLimit
          ? '#E44D25'
          : '#f00';
    }
  }

  resetCharts() {
    this.planRequestAnomaliesChartData = {
      totalAnomalies: 0,
      totalInProgress: 0,
      categories: [
        {
          name: 'Plan Request Created',
          anomalies: [],
          chart: new PieChartModel(),
        },
        {
          name: 'Plan Request Published',
          anomalies: [],
          chart: new PieChartModel(),
        },
        {
          name: 'Email Sent To Plan Requestor',
          anomalies: [],
          chart: new PieChartModel(),
        },
        {
          name: 'Email Sent To Concerned Klim Admin',
          anomalies: [],
          chart: new PieChartModel(),
        },
        {
          name: 'Send Reminder Email',
          anomalies: [],
          chart: new PieChartModel(),
        },
        {
          name: 'Plan Request Confirmed',
          anomalies: [],
          chart: new PieChartModel(),
        },
        {
          name: 'Send confirmation to KLIP',
          anomalies: [],
          chart: new PieChartModel(),
        },
        {
          name: 'Upload Plans To KPL',
          anomalies: [],
          chart: new PieChartModel(),
        },
        {
          name: 'Bulk Zip Generated',
          anomalies: [],
          chart: new PieChartModel(),
        },
        {
          name: 'Email Sent To Plan Requestor With Plans',
          anomalies: [],
          chart: new PieChartModel(),
        },
      ],
    };

    //portal: no confirmation for KLIP
    if (this.tabId === 1)
      this.planRequestAnomaliesChartData = {
        ...this.planRequestAnomaliesChartData,
        categories: [...this.planRequestAnomaliesChartData.categories].filter(
          (_, i) => i !== 6
        ),
      };
  }

  toDetails(statusIndex: number) {
    this.detailsTitle =
      this.planRequestAnomaliesChartData.categories[statusIndex].name;
    this.detailsStatus = [
      ...this.planRequestAnomaliesChartData.categories[statusIndex].anomalies,
    ];
  }

  back() {
    this.detailsStatus = undefined;
  }

  round(i: number) {
    return Math.round(i);
  }
}
