import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActiveServices, CheckObjectResultsRabbitMQ } from 'src/app/_models/activeservices.model';
import { AttachmentMaintenance } from 'src/app/_models/attachmentMaintenance.model';
import { SignalrService } from 'src/app/_services/signalr.service';
import { environment } from '../../../environments/environment';
import { Anomaly } from '../../_models/anomaly.model';
import { MonitoringService } from '../../_services/monitoring.service';

@Component({
  selector: 'app-anomaly',
  templateUrl: './anomaly.component.html',
  styleUrls: ['./anomaly.component.scss'],
})
export class AnomalyComponent implements OnInit, OnDestroy {
  availableUrls: Map<string, string> = new Map([
    ['actor', environment.actorapiurl],
    ['admin', environment.adminurl],
    ['cadaster', environment.cadasterapiurl],
    ['planrequest', environment.planrequestapiurl],
    ['monitor', environment.monitoringapiurl],
    ['workflow', environment.workflowapiurl],
    ['zoneofinterest', environment.zoneofinterestapiurl],
  ]);

  backendKeys: Map<string, string> = new Map([
    ['UrlCheck(http://actorapi.klim-cicc/version/)', 'Actor'],
    ['UrlCheck(http://cadasterapi.klim-cicc/version/)', 'Cadaster'],
    ['UrlCheck(http://planrequestapi.klim-cicc/version/)', 'Planrequest'],
    ['UrlCheck(http://workflowapi.klim-cicc/version/)', 'Workflow'],
    ['UrlCheck(http://zoneofinterestapi.klim-cicc/version/)', 'Zone of Interest'],
    ['RabbitMQCheck(RabbitMQ)', 'RabbitMQ'],
    [
      `AzureBlobStorageCheck ${environment.azureBlobStoragePrimaryUrl}`,
      'Azure blob storage',
    ],
  ]);

  checkStatusCodes: Map<number, string> = new Map();
  rabbitMQcheckStatusCodes: Map<number, string> = new Map();

  anomalies: Anomaly[] = [];
  newAnomalies: Anomaly[] = [];
  timerSubscription: Subscription;

  lastAnomalyCalculation: string;
  mailsSent: number | string;
  attachmentInfo: any[] | null;

  warningLimit: number = environment.mailsWarningLimit;
  errorLimit: number = environment.mailsErrorLimit;
  absoluteLimit: number;

  mailsError: string;

  constructor(
    private monitoringService: MonitoringService,
    private signalRService: SignalrService
  ) { }

  ngOnInit(): void {
    this.checkStatusCodes.set(0, 'error');
    this.checkStatusCodes.set(1, 'warning');
    this.checkStatusCodes.set(2, 'ok');

    this.rabbitMQcheckStatusCodes.set(0, 'Unknown');
    this.rabbitMQcheckStatusCodes.set(1, 'Unhealthy');
    this.rabbitMQcheckStatusCodes.set(2, 'Healthy');
    this.rabbitMQcheckStatusCodes.set(3, 'Warning');

    this.doCheck();

    this.timerSubscription = this.signalRService.signalRSubject$.subscribe(
      () => {
        const time = new Date();
        this.doCheck();
      }
    );
  }

  doCheck() {
    this.checkAvailableApi();
    this.checkMailConfirmations();
    this.checkAttachmentMaintenance();
    this.checkLastAnomalyCalculation();
  }

  checkAvailableApi() {
    this.newAnomalies = [];
    let requestsToDo = 1;

    this.monitoringService.checkActiveServices().subscribe(
      (statuses: ActiveServices) => {
        for (let [key, name] of this.backendKeys) {
          let anomaly = new Anomaly();
          anomaly.name = name;
          anomaly.status =
            this.checkStatusCodes.get(statuses[key]?.checkStatus) || 'error';
          anomaly.description =
            statuses[key]?.description ||
            'No response from functional monitoring backend';
          if (name == 'RabbitMQ' && statuses[key]?.results) {
            anomaly = this.createRabbitMQcheckTooltipDescription(anomaly, <CheckObjectResultsRabbitMQ><unknown>statuses[key]?.results);
            if (this.checkStatusCodes.get(statuses[key]?.checkStatus) !== this.checkStatusCodes.get(2)) {
              anomaly.status = this.checkStatusCodes.get(statuses[key]?.checkStatus) || 'error';
            };
          }
          this.newAnomalies.push(anomaly);
        }
        requestsToDo--;
        if (requestsToDo == 0) {
          this.onRequestDone();
        }
      },
      (error) => {
        for (let [_, name] of this.backendKeys) {
          let anomaly = new Anomaly();
          anomaly.name = name;
          anomaly.status = 'error';
          anomaly.description = error.message + '.'; // Reason: ' + error.error;
          this.newAnomalies.push(anomaly);
        }
        requestsToDo--;
        if (requestsToDo == 0) {
          this.onRequestDone();
        }
      }
    );
  }

  createRabbitMQcheckTooltipDescription(anomaly: Anomaly, anomalyResults: CheckObjectResultsRabbitMQ): Anomaly {
    let newDescriptionHealthHeader = '<font size=4 color=red>Unhealthy queues: </font><br>'
    let newDescriptionHealth = ''
    let newDescriptionMessagesHeader = '<font size=4 color=red>Error messages count:</font><br>'
    let newDescriptionMessages = ''

    Object.keys(anomalyResults).forEach(key => {
      let value = anomalyResults[key as keyof CheckObjectResultsRabbitMQ];
      if (this.rabbitMQcheckStatusCodes.get(value.checkStatus) != this.rabbitMQcheckStatusCodes.get(2)) {
        newDescriptionHealth += '<span>' + value.data.Name + '</span><br>';
        anomaly.status = 'warning';
      }
      if (value.data.Name.toLowerCase().includes('_error') && value.data.Messages > 0) {
        newDescriptionMessages += '<span>' + value.data.Name + ': ' + value.data.Messages + '</span><br>';
        anomaly.status = 'warning';
      }
    });
    if (newDescriptionHealth == '') {
      newDescriptionHealth = 'None<br>';
    }
    if (newDescriptionHealth == '') {
      newDescriptionMessages = 'None';
    }
    anomaly.description = newDescriptionHealthHeader + newDescriptionHealth + newDescriptionMessagesHeader + newDescriptionMessages;
    return anomaly;
  }

  onRequestDone() {
    this.anomalies = [...this.newAnomalies].sort((a, b) =>
      a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
    );
  }

  checkMailConfirmations() {
    this.monitoringService.checkSendGridMailCount().subscribe(
      (val) => {
        this.warningLimit = Number(val[0].planLimit) ? Math.round(Number(val[0].planLimit) * 0.8) : this.warningLimit;
        this.errorLimit = Number(val[0].planLimit) ? Math.round(Number(val[0].planLimit) * 0.85) : this.errorLimit;
        this.absoluteLimit = Number(val[0].planLimit) ? Number(val[0].planLimit) : this.errorLimit;
        this.mailsSent = val[0].stats[0].metrics.requests;
      },
      (error) => {
        this.mailsSent = 'Error';
        this.mailsError = error.url + ' (' + error.status + ' ' + error.statusText + ')';
        if (typeof error.error === 'string')
          this.mailsError += ': ' + error.error;
      }
    );
  }

  checkAttachmentMaintenance() {
    this.attachmentInfo = [];
    this.monitoringService.getAttachmentMaintenance().subscribe(
      (val: AttachmentMaintenance[] | null) => {
        if (!val) {
          this.attachmentInfo = null;
        } else {
          this.attachmentInfo = [];
          val.forEach((info: AttachmentMaintenance) => {
            let date_str = info.info.split(' ').splice(3, 2).join(' ');
            let date = new Date(date_str);
            info.info.includes('started')
              ? this.attachmentInfo?.push({
                title: 'Job started',
                date: date,
              })
              : this.attachmentInfo?.push({
                title: 'Job ended',
                date: date,
              });
          });
          this.attachmentInfo = [...this.attachmentInfo].sort((a, b) =>
            a.title.toLowerCase() > b.title.toLowerCase() ? -1 : 1
          );
        }
      },
      (error) => (this.attachmentInfo = [])
    );
  }

  checkLastAnomalyCalculation() {
    this.monitoringService.getLastAnomalyCalculation().subscribe(
      (result: string) => this.lastAnomalyCalculation = result,
      (error) => this.lastAnomalyCalculation = ''
    );
  }

  ngOnDestroy() {
    this.timerSubscription.unsubscribe();
  }
}
