<!-- <div class="alert alert-warning no-data-warning" *ngIf="totalInProgress === 0">
  No plan requests currently active. This may indicate a problem with the functional monitoring API. Please check in the anomalies tab if all services are working properly.
</div> -->
<div id="chartContent" class="row" *ngIf="!detailsStatus">
  <div
    *ngFor="
      let category of planRequestAnomaliesChartData.categories;
      let i = index
    "
    class="col-xs-12 mb-3"
    (click)="toDetails(i)"
    [ngClass]="
      isCollapsed
        ? 'col-sm-6 col-md-4 col-lg-3 col-xl-2'
        : 'col-sm-12 col-md-6 col-lg-4 col-xl-3'
    "
  >
    <div
      class="card text-center d-flex"
      *ngIf="planRequestAnomaliesChartData.totalAnomalies > 0"
    >
      <div class="card-header">{{ category.chart.title }}</div>
      <div class="card-body align-items-center d-flex justify-content-center">
        <ngx-charts-pie-chart
          [scheme]="category.chart.colorScheme"
          [results]="category.chart.data"
          [gradient]="category.chart.gradient"
          [doughnut]="category.chart.isDoughnut"
          [tooltipDisabled]="category.chart.disableTooltip"
          [title]="
            category.anomalies.length +
            (category.anomalies.length === 1 ? ' anomaly' : ' anomalies') +
            ' out of ' +
            totalAnomalies +
            ' anomalies total'
          "
        >
        </ngx-charts-pie-chart>
        <img
          *ngIf="category.chart.percentage === 0"
          src="/assets/ok.svg"
          class="okStatus"
        />
        <span *ngIf="category.chart.percentage > 0" class="percentage"
          >{{ category.chart.percentage }}%</span
        >
      </div>
    </div>

    <!-- temp: No anomalies, and not yet the total amount of planrequests in progress -->
    <div
      class="card text-center d-flex"
      *ngIf="planRequestAnomaliesChartData.totalAnomalies === 0"
    >
      <div class="card-header d-flex align-items-center">
        <span>{{ category.chart.title }}</span>
      </div>
      <div class="card-body align-items-center d-flex justify-content-center">
        <ngx-charts-pie-chart
          [scheme]="category.chart.colorScheme"
          [results]="[
            { name: 'total', value: 1 },
            { name: 'anomalies', value: 0 }
          ]"
          [gradient]="category.chart.gradient"
          [doughnut]="category.chart.isDoughnut"
          [tooltipDisabled]="category.chart.disableTooltip"
          title="No anomalies"
        >
        </ngx-charts-pie-chart>
        <img src="/assets/ok.svg" class="okStatus" />
      </div>
    </div>
  </div>
</div>
<div id="chartContent" class="row" *ngIf="detailsStatus">
  <app-planrequest-anomalies
    (onGoBack)="activeId === tabId && back()"
    [title]="detailsTitle"
    [planRequestAnomalies]="detailsStatus"
    class="col-12"
    style="max-height: 450px"
  ></app-planrequest-anomalies>
</div>
