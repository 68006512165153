import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { AuthenticatedUser } from 'src/app/_models/authentication.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  currentUser: AuthenticatedUser | null;
  userSub: Subscription;

  constructor(private authService: AuthenticationService) { }

  ngOnInit(): void {
    this.userSub = this.authService.authenticatedUser$.subscribe((res) => {
      this.currentUser = res;
    });
  }

  ngOnDestroy(){
    this.userSub.unsubscribe();
  }

  logout(){
    this.authService.logout();
  }

}
