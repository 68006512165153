import { Role } from "./enums.model";


export class Token {
	access_token: string | undefined;
	expires_in: number;
	refresh_token: string | undefined;
	scope: string;
	token_type: string;
	error: string | undefined;
	error_description: string | undefined;

	constructor(json?: any) {
		if (json) {
			this.access_token = json.access_token;
			this.expires_in = json.expires_in;
			this.refresh_token = json.refresh_token || null;
			this.scope = json.scope;
			this.token_type = json.token_type;
			this.error = json.error || null;
			this.error_description = json.error_description || null;
		}

	}
}

export class SpecialCase {
	fetrapi = false;
	zoiInVlaanderen = false;
	klip = false;

	constructor(jwtResult: any) {
		if (jwtResult) {
			if (jwtResult.indexOf("Fetrapi") > -1) {
				this.fetrapi = true;
			}
			if (jwtResult.indexOf("Klip") > -1) {
				this.klip = true;
			}
			if (jwtResult.indexOf("ZoiInVlaanderen") > -1) {
				this.zoiInVlaanderen = true;
			}
		}
	}
}

export class AuthenticatedUser {
	id: string;
	email: string;
	name: string;
	userroles: Role;
	language: string;
	specialcase?: SpecialCase;

	constructor(jwtResult: any) {
		this.id = jwtResult ? jwtResult.sub : '';
		this.email = jwtResult ? jwtResult.email : '';
		this.name = jwtResult ? jwtResult.name : '';
		this.userroles = jwtResult ? jwtResult.userroles : '';
		this.language = jwtResult ? jwtResult.language : '';
		this.specialcase = jwtResult ? new SpecialCase(jwtResult.specialcase) : new SpecialCase([]);
	}
}
