<div class="header d-flex justify-content-between" style="align-items: center">
  <div class="d-flex flex-grow-1" style="align-items: center">
    <img src="./../../../assets/logo.png" class="logo" alt="klim" />
    <span class="title">Functional monitoring</span>
  </div>
  <!-- <a *ngIf="currentUser" class="nav-link" id="signOut" (click)="logout()">Sign out</a> -->
  <button
    *ngIf="currentUser"
    id="navbarDropdownMenuLinkName"
    type="button"
    class="btn head-drop-bg"
    (click)="logout()"
  >
    Sign out
  </button>
</div>
