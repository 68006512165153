<div class="loginComp d-flex flex-column">
  <h1 class="tab-login">Sign in</h1>

  <div class="bg-lightblue mx-auto">
    <form [formGroup]="loginForm" class="form" (ngSubmit)="onSubmit()">
      <div
        class="input-group mb-2 mr-sm-2 mb-sm-0"
        [ngClass]="{
          'has-error':
            !loginForm.controls['email'].valid &&
            loginForm.controls['email'].touched,
          'has-success':
            loginForm.controls['email'].valid &&
            loginForm.controls['email'].touched
        }"
      >
        <input
          type="email"
          class="form-control"
          id="Email"
          formControlName="email"
          placeholder="E-mail address"
        />
      </div>
      <div
        class="alert alert-danger"
        id="EmailError"
        *ngIf="
          loginForm.hasError('email', ['email']) &&
          loginForm.controls['email'].touched
        "
      >
        Please enter a valid e-mail address
      </div>

      <br />
      <div class="input-group mb-2 mr-sm-2 mb-sm-0">
        <input
          type="password"
          class="form-control"
          id="password"
          placeholder="Password"
          formControlName="password"
        />
      </div>
      <div class="container" *ngIf="requestError">
        <div class="column justify-content-md-center">
          <p class="invalid-login mt-3">{{ requestError }}</p>
        </div>
      </div>
      <button type="submit" class="btn btn-primary mt-3">Sign in</button>
    </form>
  </div>
</div>
