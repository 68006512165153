import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './auth/authentication.service';
import { SignalrService } from './_services/signalr.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private authService: AuthenticationService, private signalRService: SignalrService) { }

  ngOnInit() {
    this.authService.autoLogin();
    this.signalRService.startConnection();
  }
}
