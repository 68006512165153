import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Enums } from 'src/app/_models/enums.model';
import { PlanRequest } from 'src/app/_models/planrequest.model';
import {
  ActiveWorkFlow,
  Actor,
  WorkFlow,
} from 'src/app/_models/workflow.model';
import { MonitoringService } from 'src/app/_services/monitoring.service';

@Component({
  selector: 'app-planrequest',
  templateUrl: './planrequest.component.html',
  styleUrls: ['./planrequest.component.scss'],
})
export class PlanrequestComponent implements OnInit, OnChanges {
  @Input() isCollapsed: boolean;

  enums: Enums;
  @Input() planReqId: string = '';
  selectedPlanRequest: PlanRequest;
  admins: Actor[];
  workflow: WorkFlow;
  error: string | undefined;

  constructor(private monitoringService: MonitoringService) {}

  ngOnInit(): void {
    this.enums = new Enums();
    if (this.planReqId) this.clickSearch();
  }

  ngOnChanges() {
    if (this.planReqId) this.clickSearch();
  }

  clickSearch() {
    !this.planReqId || this.planReqId.length === 0
      ? (this.error = 'Please enter a valid plan request id.')
      : this.monitoringService.getActiveWorkFlow(this.planReqId).subscribe(
          (activeWorkflow: ActiveWorkFlow) => {
            if (activeWorkflow) {
              this.error = undefined;
              this.workflow = { ...activeWorkflow.workFlow };
              this.selectedPlanRequest = { ...activeWorkflow.planRequest };
              if (activeWorkflow.actors) {
                this.admins = [...activeWorkflow.actors];
                this.admins = [
                  ...this.admins.map((admin) => {
                    admin.details = {
                      status: 'Pending',
                      uploaded: false,
                      uploadDate: '/',
                    };
                    return this.getAdminDetails(admin);
                  }),
                ];
              } else {
                this.admins = [];
              }
            } else {
              this.error = 'Something unexpected went wrong.';
            }
          },
          (error) => {
            if (error.error) {
              this.error =
                error.error.errors && error.error.errors.id
                  ? error.error.errors.id[0]
                  : error.error;
            } else {
              this.error = 'Something unexpected went wrong.';
            }
          }
        );
  }

  getAdminDetails(admin: Actor) {
    const adminworkflow = this.workflow.adminWorkFlows.find(
      (el) => el.mainAdminId === admin.id
    );

    if (adminworkflow)
      admin.details = {
        status: this.enums.enumAdminWorkFlowStatus.get(adminworkflow.status),
        uploaded:
          [...adminworkflow.workFlowAttachments].filter(
            (attachment) => !attachment.systemGenerated
          ).length > 0,
        uploadDate:
          [...adminworkflow.workFlowAttachments].filter(
            (attachment) => !attachment.systemGenerated
          ).length > 0
            ? [...adminworkflow.workFlowAttachments]
                .filter(
                  (attachment) => !attachment.systemGenerated //&& !!attachment.originalFilename
                )
                .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))[0]
                .createdAt
            : '/',
      };
    return admin;
  }
}
