// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  oauthclientid: 'klim-dev',
  fmbackendurl: "https://functionalmonitoring.klim-cicc-dev.be/backend",
  actorapiurl: "https://klim-cicc-dev.be/actor",
  adminurl: "https://klim-cicc-dev.be/search",
  cadasterapiurl: "https://klim-cicc-dev.be/cadaster",
  geoserverurl: "https://geoserver.klim-cicc-dev.be/geoserver",
  monitoringapiurl: "https://klim-cicc-dev.be/monitoring",
  planrequestapiurl: "https://klim-cicc-dev.be/planrequest",
  workflowapiurl: "https://klim-cicc-dev.be/workflow",
  zoneofinterestapiurl: "https://klim-cicc-dev.be/zoneofinterest",
  azureBlobStoragePrimaryUrl: "https://storageklimciccdev.blob.core.windows.net/",
  azureBlobStorageSecondaryUrl: "https://storageklimciccdev-secondary.blob.core.windows.net/",
  totalAnomalyCountWarning: 10,
  totalAnomalyCountError: 50,
  chartWarningPercentageLimit: 15,
  mailsWarningLimit: 260000,
  mailsErrorLimit: 270000
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
