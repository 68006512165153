import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { AuthenticatedUser } from 'src/app/_models/authentication.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  currentUser: AuthenticatedUser | null;
  userSub: Subscription;

  private onDestroy$: Subject<void> = new Subject<void>();

  loginForm: FormGroup;

  requestError: string;

  constructor(
    private authService: AuthenticationService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  onSubmit() {
    const email = this.loginForm.get('email')?.value;
    const pwd = this.loginForm.get('password')?.value;

    this.authService.login(email, pwd).subscribe(
      (res) => {
        // this.authService.setSession(res);
        this.router.navigate(['']);
      },
      (error) => {
        switch (error.error.error_description) {
          case 'Invalid user credentials.':
            this.requestError = 'The e-mail address or password is incorrect!';
            break;
          case "The mandatory 'username' and/or 'password' parameters are missing.":
            this.requestError = 'Please enter e-mail address and password.';
            break;
          case 'Unauthorized login credentials.':
            this.requestError = 'The login credentials are not valid.';
            break;
          default:
            this.requestError = 'Login failed.';
            break;
        }
      }
    );
  }

  createLoginForm() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.createLoginForm();
    this.userSub = this.authService.authenticatedUser$.subscribe((res) => {
      this.currentUser = res;
    });
    if (this.currentUser) this.router.navigate(['/']);
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
  }
}
