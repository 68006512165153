import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { SharedModule } from './../shared/shared.module';
import { AnomalyComponent } from './anomaly/anomaly.component';
import { ChartsComponent } from './charts/charts.component';
import { MainComponent } from './main/main.component';
import { PlanrequestAnomaliesComponent } from './planrequest-anomalies/planrequest-anomalies.component';
import { NgbdSortableHeader } from './planrequest-anomalies/sortable-header.directive';
import { PlanrequestComponent } from './planrequest/planrequest.component';

@NgModule({
  declarations: [
    ChartsComponent,
    MainComponent,
    PlanrequestComponent,
    AnomalyComponent,
    PlanrequestAnomaliesComponent,
    NgbdSortableHeader,
  ],
  imports: [
    SharedModule,
    BrowserAnimationsModule,
    NgxChartsModule,
    FormsModule,
  ],
  exports: [MainComponent],
})
export class ContentModule {}
