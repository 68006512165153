import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthenticatedUser, Token } from '../_models/authentication.model';
import { Role } from '../_models/enums.model';

const TOKEN_KEY = 'KLIM-CICC.functional-monitoring.token';
const ACCESS_TOKEN_KEY = 'KLIM-CICC.functional-monitoring.access_token';
const REFRESH_TOKEN_KEY = 'KLIM-CICC.functional-monitoring.refresh_token';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private authenticatedUserSource: BehaviorSubject<AuthenticatedUser | null> =
    new BehaviorSubject<AuthenticatedUser | null>(null);
  authenticatedUser$: Observable<AuthenticatedUser | null> =
    this.authenticatedUserSource.asObservable();

  private accesTokenExpirationTimer: any; //todo: ?

  jwt: JwtHelperService = new JwtHelperService();

  constructor(private http: HttpClient, private router: Router) { }

  login(email: string, password: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    const body = `client_id=${environment.oauthclientid
      }&grant_type=password&username=${encodeURIComponent(
        email
      )}&password=${encodeURIComponent(password)}`;

    return this.http
      .post<Token>(environment.actorapiurl + '/token', body, {
        headers: headers,
      })
      .pipe(
        tap((token) => {
          this.setSession(token);
        })
      );
  }

  autoLogin() {
    const token = this.getToken();
    if (token) this.setSession(token);
  }

  logout() {
    this.clearLocalStorage();
    this.authenticatedUserSource.next(null);
    if (this.accesTokenExpirationTimer) {
      clearTimeout(this.accesTokenExpirationTimer);
      this.accesTokenExpirationTimer = null;
    }
    this.router.navigate(['login']);
  }

  setAccessTokenExpirationTimer(token: Token) {
    const expirationDuration =
      this.jwt.decodeToken(token.access_token).exp * 1000 - Date.now();
    if (expirationDuration > 0) {
      this.accesTokenExpirationTimer = setTimeout(() => {
        const refresh_token = this.getRefreshToken();
        if (refresh_token)
          this.refreshToken(refresh_token).subscribe(
            (res) => { },
            (error) => {
              this.logout();
            }
          );
      }, expirationDuration);
    } else {
      this.logout();
    }
  }

  refreshToken(refreshToken: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    const body = `client_id=${environment.oauthclientid}&grant_type=refresh_token&refresh_token=${refreshToken}`;
    return this.http
      .post<Token>(environment.actorapiurl + '/token', body, {
        headers: headers,
      })
      .pipe(
        tap((token) => {
          this.setSession(token);
        })
      );
  }

  isFM(user: AuthenticatedUser): boolean {
    const userRole: number = Number(user.userroles);
    return Role.FunctionalManager <= userRole; // at least FM, todo: helpdesk?
  }

  //"tokenservice" methods
  setSession(token: Token): void {
    const user = new AuthenticatedUser(
      this.jwt.decodeToken(token ? token.access_token : '')
    );
    if (this.isFM(user)) {
      this.saveAccessToken(token.access_token);
      this.saveRefreshToken(token.refresh_token);
      this.saveToken(token);
      this.authenticatedUserSource.next(user);

      this.setAccessTokenExpirationTimer(token);
    } else {
      this.authenticatedUserSource.next(null);
      throw new HttpErrorResponse({
        error: {
          error: 'invalid_request',
          error_description: 'Unauthorized login credentials.',
        },
        status: 400,
      });
    }
  }

  saveToken(token: Token): void {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
  }

  getToken(): Token | null {
    const tokenString = localStorage.getItem(TOKEN_KEY);
    if (tokenString) {
      const token: Token = JSON.parse(tokenString);
      return token;
    }
    return null;
  }

  saveAccessToken(accessToken: string | undefined): void {
    if (!accessToken) accessToken = ';';
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
  }

  getAccessToken(): string | null {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
  }

  saveRefreshToken(refreshToken: string | undefined): void {
    if (!refreshToken) refreshToken = '';
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  }

  getRefreshToken(): string | null {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
  }

  clearLocalStorage() {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  }
}
