export class Enums {
  enumPlanRequestType: Map<number, string>;
  enumPlanRequestStatus: Map<number, string>;
  enumWorkType: Map<number, string>;
  enumActorType: Map<number, string>;
  enumExecutionMethod: Map<number, string>;
  enumAdminWorkFlowStatus: Map<number, string>;
  enumKlpStatus: Map<number, string>;
  enumAnomalyReason: Map<number, string>;

  constructor() {
    //todo: KEY VALUES NOT CORRECT!!!!
    this.enumPlanRequestType = new Map<number, string>();
    this.enumPlanRequestType.set(4, 'Worksite announcement');
    this.enumPlanRequestType.set(
      5,
      'Consultation for planned works/study phase'
    );
    this.enumPlanRequestType.set(7, 'Easement request');
    this.enumPlanRequestType.set(8, 'List of relevant utilities');
    this.enumPlanRequestType.set(9, 'Fetrapi');
    this.enumPlanRequestType.set(10, 'Powalco');

    this.enumPlanRequestStatus = new Map<number, string>();
    this.enumPlanRequestStatus.set(0, 'Created');
    this.enumPlanRequestStatus.set(1, 'Workzone Drawn');
    this.enumPlanRequestStatus.set(2, 'Canceled');
    this.enumPlanRequestStatus.set(3, 'Completed');
    this.enumPlanRequestStatus.set(4, 'Published');
    this.enumPlanRequestStatus.set(5, 'Abandoned');
    this.enumPlanRequestStatus.set(6, 'Workflow started');
    this.enumPlanRequestStatus.set(7, 'Partially Confirmed');
    this.enumPlanRequestStatus.set(8, 'Finished');
    this.enumPlanRequestStatus.set(9, 'Archived');

    this.enumWorkType = new Map<number, string>();
    this.enumWorkType.set(0, 'None');
    this.enumWorkType.set(1, 'Cables/pipes: installation');
    this.enumWorkType.set(2, 'Soil study');
    this.enumWorkType.set(4, 'Roadworks');
    this.enumWorkType.set(5, 'Foundation works');
    this.enumWorkType.set(6, 'Extension/renovation of buildings');
    this.enumWorkType.set(7, 'Other (underground)');
    this.enumWorkType.set(8, 'Other (above-ground)');
    this.enumWorkType.set(9, 'New building');
    this.enumWorkType.set(10, 'Agricultural/forest/garden works');
    this.enumWorkType.set(11, 'Demolition');
    this.enumWorkType.set(12, 'Cables/pipes: occasional openings'); //todo: correct name (PreciseCablePipe)???
    this.enumWorkType.set(13, 'Railway works');
    this.enumWorkType.set(14, 'Construction of civil engineering structures');
    this.enumWorkType.set(15, 'Hydraulic works');
    this.enumWorkType.set(16, 'Temporary occupation of land');
    this.enumWorkType.set(17, 'Maintenance of civil engineering structures');
    this.enumWorkType.set(
      18,
      'Masts, pylons and wind turbines (construction of)'
    );
    this.enumWorkType.set(19, 'Construction of an underground chamber');

    this.enumActorType = new Map<number, string>();
    this.enumActorType.set(1, 'Contractor');
    this.enumActorType.set(2, 'Subcontractor');
    this.enumActorType.set(4, 'Private person');
    this.enumActorType.set(6, 'Constructor');
    this.enumActorType.set(7, 'Municipal Administration');
    this.enumActorType.set(9, 'Engineering Company');
    this.enumActorType.set(10, 'Notary');
    this.enumActorType.set(11, 'Other');
    this.enumActorType.set(120, 'Architect');

    this.enumExecutionMethod = new Map<number, string>();
    this.enumExecutionMethod.set(0, 'None');
    this.enumExecutionMethod.set(1, 'Mechanical Inc Drill'); //todo: name
    this.enumExecutionMethod.set(2, 'Mechanical Heavy');
    this.enumExecutionMethod.set(3, 'Manual');
    this.enumExecutionMethod.set(4, 'Mechanical Light');
    this.enumExecutionMethod.set(5, 'Special Construction');

    this.enumAdminWorkFlowStatus = new Map<number, string>();
    this.enumAdminWorkFlowStatus.set(0, 'Pending');
    this.enumAdminWorkFlowStatus.set(1, 'Confirmed');

    this.enumKlpStatus = new Map<number, string>();
    this.enumKlpStatus.set(0, 'Unanswered');
    this.enumKlpStatus.set(1, 'Answered');

    this.enumAnomalyReason = new Map<number, string>();
    this.enumAnomalyReason.set(0, 'None');
    this.enumAnomalyReason.set(1, 'Telemetry exists but database record missing');
  }
}

export enum PlanRequestStatus {
  Created = 0,
  WorkZoneDrawn = 1,
  Canceled = 2,
  Completed = 3,
  Published = 4,
  Abandoned = 5,
  WorkFlowStarted = 6,
  PartiallyConfirmed = 7,
  Finished = 8,
  Archived = 9
}

export enum PlanRequestStage {
  PlanRequestCreated = 1 << 0, // 1
  PlanRequestPublished = 1 << 1, // 2
  EmailSendToPlanRequestor = 1 << 2, // 4
  EmailSendToConcernedKlimAdmin = 1 << 3, // 8
  SendReminderEmail = 1 << 4, // 16
  PlanRequestConfirmed = 1 << 5, // 32
  SendConfirmationKlip = 1 << 6, // 64
  UploadPlansToKPL = 1 << 7, // 128
  BulkZipGenerated = 1 << 8, // 256
  EmailSendToPlanRequestorWithPlans = 1 << 9, // 512
}

export enum AnomalyReason {
  None = 0,
  TelemetryExistDbRecordMissing = 1
}

export enum Role {
	User = 1,
	PlanRequestor = 2,
	MainPlanRequestor = 4,
	NetworkAdmin = 8,
	MainNetworkAdmin = 16,
	ZoneOfInterestAdmin = 32,
	ZoneOfInterestOwner = 64,
	M2M = 128,
	PlanRequestorM2M = 256,
	NetworkAdminM2M = 512,
	FunctionalManager = 1024,
	TechnicalManager = 2048
}
