<div id="anomalyContent">
  <div class="anomaly-subsection last-update">
    <span class="d-flex flex-row justify-content-between w-100" style="margin-bottom: 1rem">
      <span class="d-flex"><b class="headerText">Last update: </b></span>
      <span class="d-flex updateTime">{{
        lastAnomalyCalculation | date: "dd/MM/yyyy HH:mm:ss"
        }}</span>
    </span>
  </div>
  <div class="anomaly-subsection">
    <span class="headerText">API Services Status</span>
    <ul class="list-group">
      <li *ngFor="let an of anomalies" class="list-group-item d-flex justify-content-between align-items-center">
        <ng-template #tipContent>
          <div [innerHTML]="an.description">

          </div>
        </ng-template>
        <span class="badge badge-pill" placement="left" [ngbTooltip]="tipContent" container="body" [ngClass]="{
            badgeOk: an.status === 'ok',
            badgeSomeError: an.status === 'warning',
            badgeAllError: an.status === 'error'
          }">0</span>
        <span class="anomaly-name">{{ an.name }}</span>
      </li>
    </ul>
  </div>
  <div class="anomaly-subsection">
    <span class="headerText">SendGrid Mail Status</span>
    <ul class="list-group">
      <li class="list-group-item d-flex justify-content-between align-items-center">
        <ng-template #t>{{ mailsError }}</ng-template>
        <span class="badge badge-pill" placement="left" [disableTooltip]="mailsSent !== 'Error'" [ngbTooltip]="t"
          container="body" [ngClass]="{
            badgeOkText: mailsSent < warningLimit,
            badgeSomeErrorText:
              mailsSent >= warningLimit && mailsSent < errorLimit,
            badgeAllErrorText: mailsSent >= errorLimit || (mailsSent === 'Error')
          }">{{ (mailsSent | number:'':'nl-BE') || mailsSent }} / {{absoluteLimit}}</span>
        <span class="anomaly-name">Mail confirmations sent</span>
      </li>
    </ul>
  </div>
  <div class="anomaly-subsection">
    <span class="headerText" style="width: 100%;">Attachment Maintenance</span>
    <ul class="list-group">
      <li *ngFor="let info of attachmentInfo" class="list-group-item d-flex justify-content-between align-items-center">
        <span class="att-title">{{info.title}}</span>
        <span class="anomaly-name">{{info.date | date:'dd/MM/yyyy HH:mm:ss'}}</span>
      </li>
      <li *ngIf="attachmentInfo?.length === 0"
        class="list-group-item d-flex justify-content-between align-items-center">
        <span class="att-title" style="color: #ee5a24;">Error retrieving attachment maintenance data</span>
      </li>
      <li *ngIf="!attachmentInfo" class="list-group-item d-flex justify-content-between align-items-center">
        <span class="att-title" style="color: red;">Attachment maintenance scheduler failed.</span>
      </li>
    </ul>
  </div>
</div>