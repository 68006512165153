import { PlanRequestAnomaly } from "./planrequest.model";

export class PieChartModel {
  title: string;
  data: ChartModelData[];
  percentage: number;
  colorScheme = {
    domain: ['#5AA454', '#E44D25']
  }
  disableTooltip = true;
  gradient = true;
  isDoughnut = true;
}

export class PlanReqAnomalyChart extends PieChartModel {
  anomalies: PlanRequestAnomaly[];
}

export class ChartModelData {
  name: string;
  value: number;
}
