import { DecimalPipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Enums, PlanRequestStage } from 'src/app/_models/enums.model';
import { PlanRequestAnomaly } from 'src/app/_models/planrequest.model';
import { MonitoringService } from 'src/app/_services/monitoring.service';
import {
  NgbdSortableHeader,
  SortColumn,
  SortDirection,
  SortEvent,
} from './sortable-header.directive';

@Component({
  selector: 'app-planrequest-anomalies',
  templateUrl: './planrequest-anomalies.component.html',
  styleUrls: ['./planrequest-anomalies.component.scss'],
  providers: [DecimalPipe],
})
export class PlanrequestAnomaliesComponent implements OnInit {
  constructor(private monitoringService: MonitoringService) {}

  @Input() title: string;
  @Input() planRequestAnomalies: PlanRequestAnomaly[];
  @Output() onGoBack: EventEmitter<void> = new EventEmitter();

  @HostListener('document:keydown.escape', ['$event']) back() {
    this.onGoBack.emit();
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  planRequestAnomaliesPaged: PlanRequestAnomaly[];
  page = 1;
  pageSize = 6;
  collectionSize: number;
  pages: number;
  sortColumn: SortColumn;
  sortDirection: SortDirection;

  enums: Enums;

  ngOnInit(): void {
    this.collectionSize = this.planRequestAnomalies.length;
    this.refresh();
    this.enums = new Enums();
  }

  refresh() {
    let anomalies = this.sort(
      this.planRequestAnomalies,
      this.sortColumn,
      this.sortDirection
    );
    this.pages = Math.ceil(this.collectionSize / this.pageSize);
    this.planRequestAnomaliesPaged = anomalies.slice(
      (this.page - 1) * this.pageSize,
      (this.page - 1) * this.pageSize + this.pageSize
    );
  }
  selectPage(page: string) {
    this.page = parseInt(page) || 1;
    this.refresh();
  }

  formatInput(input: HTMLInputElement) {
    input.value = input.value.replace(/[^0-9]/g, '');
  }

  onSort(ev: SortEvent | Event) {
    const column = (ev as SortEvent).column;
    const direction = (ev as SortEvent).direction;

    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.sortColumn = column;
    this.sortDirection = direction;
    this.refresh();
  }

  compare = (v1: string | number, v2: string | number) =>
    v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
  sort(
    anomalies: PlanRequestAnomaly[],
    column: SortColumn,
    direction: string
  ): PlanRequestAnomaly[] {
    if (direction === '' || column === '') {
      return anomalies;
    } else {
      return [...anomalies].sort((a, b) => {
        const res = this.compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      });
    }
  }

  @Output() onToDetails: EventEmitter<string> = new EventEmitter();
  toDetails(planReqId: string) {
    // this.onToDetails.emit(planReqId);
    this.monitoringService.onToDetails.emit(planReqId);
  }

  getPlanRequestStageText(stage: number) {
    let status = '';
    if (stage & PlanRequestStage.PlanRequestCreated)
      status += 'Planrequest created';
    if (stage & PlanRequestStage.PlanRequestPublished)
      status += (status.length > 0 ? ', ' : '') + 'Planrequest published';
    if (stage & PlanRequestStage.EmailSendToPlanRequestor)
      status +=
        (status.length > 0 ? ', ' : '') + 'Email sent to plan requestor';
    if (stage & PlanRequestStage.EmailSendToConcernedKlimAdmin)
      status +=
        (status.length > 0 ? ', ' : '') + 'Email sent to concerned KLIM admin';
    if (stage & PlanRequestStage.SendReminderEmail)
      status += (status.length > 0 ? ', ' : '') + 'Reminder email sent';
    if (stage & PlanRequestStage.PlanRequestConfirmed)
      status += (status.length > 0 ? ', ' : '') + 'Planrequest confirmed';
    if (stage & PlanRequestStage.SendConfirmationKlip)
      status += (status.length > 0 ? ', ' : '') + 'Send confirmation to KLIP';
    if (stage & PlanRequestStage.UploadPlansToKPL)
      status += (status.length > 0 ? ', ' : '') + 'Upload plans to KLP';
    if (stage & PlanRequestStage.BulkZipGenerated)
      status += (status.length > 0 ? ', ' : '') + 'Bulk zip generated';
    if (stage & PlanRequestStage.EmailSendToPlanRequestorWithPlans)
      status +=
        (status.length > 0 ? ', ' : '') +
        'Email sent to plan requestor with plans';
    return status;
  }
}
