import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbCollapse } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription, throwError } from 'rxjs';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { InProgress } from 'src/app/_models/inProgress.model';
import {
  PlanRequestAnomaly
} from 'src/app/_models/planrequest.model';
import { MonitoringService } from 'src/app/_services/monitoring.service';
import { SignalrService } from 'src/app/_services/signalr.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit, OnDestroy {
  userSub: Subscription;

  isCollapsed = true;
  activeId: number = 1;
  activeIdObs: Subject<number> = new Subject<number>();
  timerSubscription: Subscription;
  isLoading: boolean = true;
  anomaliesLoading: boolean = false;
  inProgressLoading: boolean = false;
  planRequestId: string;

  totalInProgress: number;
  totalInProgressPortal: number;
  totalInProgressWeb: number;
  totalAnomaliesPortal: number;
  totalAnomaliesWeb: number;
  planRequestAnomaliesPortal: PlanRequestAnomaly[] = [];
  planRequestAnomaliesWeb: PlanRequestAnomaly[] = [];

  warningLimit = environment.totalAnomalyCountWarning;
  errorLimit = environment.totalAnomalyCountError;

  //todo: very general message: maybe more specific?
  errorMsg = `An error occured trying to reach the KLIM Functional Monitoring backend, please try refreshing the page.`;
  httpError: string | undefined;
  endPointError: string;
  errorReason: string;
  errors: any[] = [];

  constructor(
    private monitoringService: MonitoringService,
    private authService: AuthenticationService,
    private signalRService: SignalrService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.refreshData();
    this.timerSubscription = this.signalRService.signalRSubject$.subscribe(
      (_) => {
        const time = new Date();
        this.refreshData();
      }
    );
    this.monitoringService.onToDetails.subscribe((id) => {
      this.activeId = 3;
      this.planRequestId = id;
    });
    this.userSub = this.authService.authenticatedUser$.subscribe((res) => {
      if (res === null) this.router.navigate(['login']);
    });
  }

  ngOnDestroy(): void {
    this.userSub.unsubscribe();
  }

  refreshData(): void {
    this.errors = [];
    this.getInProgressCount();
    this.updatePlanRequesAnomalies();
  }

  onActiveIdChange(id: number): void {
    this.activeIdObs.next(id);
  }

  clickCollapse(collapse: NgbCollapse): void {
    collapse.animation = false;
    collapse.toggle();
  }

  getInProgressCount(): void {
    this.monitoringService.getPlanRequestsInProgress().subscribe(
      (inProgress: InProgress) => {
        this.totalInProgressPortal = inProgress.portalPlanrequests;
        this.totalInProgressWeb = inProgress.webservicePlanrequests;
        this.totalInProgress = this.totalInProgressPortal + this.totalInProgressWeb;

        this.inProgressLoading = false;
        this.isLoading = this.inProgressLoading || this.anomaliesLoading; //check if updatePlanRequesAnomalies() is done
      },
      (error) => {
        const endPointError = error.url;
        let errorReason = error.status + ' ' + error.statusText;
        if (typeof error.error === 'string')
          errorReason += ': ' + error.error;
        this.errors.push(
          {
            endPointError: endPointError,
            errorReason: errorReason
          }
        );
        this.isLoading = false;
      }
    );
  }

  updatePlanRequesAnomalies(): void {
    this.monitoringService.getPlanRequestAnomalies().subscribe(
      (anomalies: PlanRequestAnomaly[]) => {
        this.planRequestAnomaliesPortal = [...anomalies].filter(
          (el) => el.sysFrom === '4'
        );
        this.planRequestAnomaliesWeb = [...anomalies].filter(
          (el) => el.sysFrom !== '4'
        );

        this.totalAnomaliesPortal = this.planRequestAnomaliesPortal.length;
        this.totalAnomaliesWeb = this.planRequestAnomaliesWeb.length;

        this.anomaliesLoading = false;
        this.isLoading = this.inProgressLoading || this.anomaliesLoading; //check if getInProgressCount() is doneµ
      },
      (error) => {
        const endPointError = error.url;
        let errorReason = error.status + ' ' + error.statusText;
        if (typeof error.error === 'string')
          errorReason += ': ' + error.error;
        this.errors.push(
          {
            endPointError: endPointError,
            errorReason: errorReason
          }
        );
        this.isLoading = false;
      }
    );
  }
}
