<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
<div class="row d-flex" style="margin: 1rem 1rem 0 1rem">
  <div class="col-9" style="padding-left: 0">
    <ul
      ngbNav
      #nav="ngbNav"
      [(activeId)]="activeId"
      class="nav-tabs justify-content-left"
      [destroyOnHide]="false"
      (activeIdChange)="onActiveIdChange($event)"
    >
      <li [ngbNavItem]="1">
        <a ngbNavLink class="navTab" style="margin-left: 0">
          <span class="navText">Portal</span>
          <span
            *ngIf="totalAnomaliesPortal >= 0  && totalInProgressPortal >= 0 "
            style="float: right"
            class="badge badge-pill"
            [title]="totalAnomaliesPortal + ' anomalies out of ' + totalInProgressPortal + ' planrequests in progress'"
            [ngClass]="{
              'badgeOkText': (totalAnomaliesPortal) < warningLimit,
              'badgeSomeErrorText':
                (totalAnomaliesPortal) >= warningLimit &&
                (totalAnomaliesPortal) < errorLimit,
              'badgeAllErrorText': (totalAnomaliesPortal) >= errorLimit
            }"
            >{{ totalAnomaliesPortal }} / {{ totalInProgressPortal }}</span
          >
        </a>
        <ng-template ngbNavContent>
          <app-charts
            *ngIf="
              !isLoading &&
              !errors.length &&
              totalInProgress !== undefined &&
              totalInProgressPortal !== undefined
            "
            [tabId]="1"
            [activeId]="activeId"
            [activeIdChangeObs]="activeIdObs"
            [isCollapsed]="isCollapsed"
            [planrequestAnomalies]="planRequestAnomaliesPortal"
            [totalInProgress]="totalInProgressPortal"
          ></app-charts>
          <div *ngIf="errors.length" >
            <!-- todo: separate error comp? -->
            <div *ngFor="let error of errors" class="alert alert-danger" role="alert">
              <h4>Error</h4>
              <p>{{ httpError }}</p>
              <p><b>Endpoint:</b> {{ error.endPointError }}</p>
              <p><b>Reason:</b> {{ error.errorReason }}</p>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink class="navTab">
          <span class="navText">Web Services</span>
          <span
            *ngIf="totalAnomaliesWeb >= 0 && totalInProgressWeb >= 0 "
            style="float: right"
            class="badge badge-pill"
            [title]="totalAnomaliesWeb + ' anomalies out of ' + totalInProgressWeb + ' planrequests in progress'"
            [ngClass]="{
              'badgeOkText': (totalAnomaliesWeb) < warningLimit,
              'badgeSomeErrorText':
              (totalAnomaliesWeb) >= warningLimit &&
              (totalAnomaliesWeb) < errorLimit,
              'badgeAllErrorText': (totalAnomaliesWeb) >= errorLimit
            }"
            >{{ totalAnomaliesWeb }} / {{ totalInProgressWeb }}</span
          >
        </a>
        <ng-template ngbNavContent>
          <div *ngIf="errors.length" >
            <!-- todo: separate error comp? -->
            <div *ngFor="let error of errors" class="alert alert-danger" role="alert">
              <h4>Error</h4>
              <p>{{ httpError }}</p>
              <p><b>Endpoint:</b> {{ error.endPointError }}</p>
              <p><b>Reason:</b> {{ error.errorReason }}</p>
            </div>
          </div>
          <app-charts
            *ngIf="
              !isLoading &&
              !errors.length &&
              totalInProgress !== undefined &&
              totalInProgressWeb !== undefined
            "
            [tabId]="2"
            [activeId]="activeId"
            [activeIdChangeObs]="activeIdObs"
            [isCollapsed]="isCollapsed"
            [planrequestAnomalies]="planRequestAnomaliesWeb"
            [totalInProgress]="totalInProgressWeb"
          ></app-charts>
        </ng-template>
      </li>
      <li [ngbNavItem]="3">
        <a ngbNavLink class="navTab">
          <span class="navText">Planrequest details</span>
        </a>
        <ng-template ngbNavContent>
          <app-planrequest
            [isCollapsed]="isCollapsed"
            [planReqId]="planRequestId"
          ></app-planrequest>
        </ng-template>
      </li>
    </ul>
  </div>
  <div class="col-3" style="padding-right: 0; width: 100%">
    <div class="collapseTab">
      <a
        class="navTab nav-link"
        [ngClass]="{ active: !isCollapsed }"
        (click)="clickCollapse(collapse)"
        [attr.aria-expanded]="!isCollapsed"
        aria-controls="collapseExample"
      >
        <span class="navText">Anomalies</span>
      </a>
    </div>
  </div>
</div>
<div class="row d-flex" style="margin: 0 1rem 1rem 1rem; height: 100%">

  <div
    [ngbNavOutlet]="nav"
    class="mt-2"
    [ngClass]="isCollapsed ? 'width-collapsed' : 'width-shown'"
  >
  </div>

  <div
    #collapse="ngbCollapse"
    [(ngbCollapse)]="isCollapsed"
    class="anomalies mt-2"
    [ngClass]="isCollapsed ? 'anomalies-collapsed' : 'anomalies-shown'"
    [style.margin-left]="isCollapsed ? '0' : '10px'"
  >
    <div class="card">
      <div class="card-body">
        <app-anomaly></app-anomaly>
      </div>
    </div>
  </div>
</div>
