<div id="planRequestDetail">
  <div class="row" id="planRequestSearch">
    <div class="container-fluid planrequestContainer col-12 d-flex flex-row">
      <input type="text" class="form-control planrequestIdInput" [style.border]="error && '1px solid red'"
        aria-describedby="search planrequest" [(ngModel)]="planReqId" />
      <button type="button" class="btn buttonSearch" (click)="clickSearch()">
        Search
      </button>
    </div>
  </div>
  <div class="row" id="planRequestContent">
    <div class="col-12" style="padding: 0" *ngIf="selectedPlanRequest && !error">
      <p><b>Planrequest Id: </b>{{ selectedPlanRequest.id }}</p>
      <div class="planrequestContainer">
        <table class="table table-striped planrequestInfo">
          <thead>
            <tr>
              <th scope="col">MessId</th>
              <th scope="col">Reference</th>
              <th scope="col">Report type</th>
              <th scope="col">Work type</th>
              <th scope="col">Source</th>
              <th scope="col">Status</th>
              <th scope="col">Created</th>
              <th scope="col">Published</th>
              <th scope="col">Finished</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ selectedPlanRequest.messId }}</td>
              <td>{{ selectedPlanRequest.announcerIdentification }}</td>
              <td>
                {{
                enums.enumPlanRequestType.get(
                selectedPlanRequest.planRequestType
                )
                }}
              </td>
              <td>
                {{ enums.enumWorkType.get(selectedPlanRequest.workType) }}
              </td>
              <td>
                {{
                selectedPlanRequest.sysFrom === "4"
                ? "Portal"
                : "Web services"
                }}
              </td>
              <td>
                {{
                enums.enumPlanRequestStatus.get(selectedPlanRequest.status)
                }}
              </td>
              <td>
                {{
                selectedPlanRequest.createdAt
                ? (selectedPlanRequest.createdAt | date: "dd/MM/yyyy")
                : "/"
                }}
              </td>
              <td>
                {{
                selectedPlanRequest.publishedAt
                ? (selectedPlanRequest.publishedAt | date: "dd/MM/yyyy")
                : "/"
                }}
              </td>
              <td>
                {{
                selectedPlanRequest.finishedAt
                ? (selectedPlanRequest.finishedAt | date: "dd/MM/yyyy")
                : "/"
                }}
              </td>
            </tr>
          </tbody>
          <thead>
            <tr>
              <th scope="col">Requestor name</th>
              <th scope="col">Requestor E-mail</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ selectedPlanRequest.planRequestorName }}</td>
              <td>{{ selectedPlanRequest.planRequestorEmail }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <table class="table">
        <thead>
          <tr class="headerTable">
            <th scope="col">Concerned Admin</th>
            <th scope="col">Admin Workflow Status</th>
            <th scope="col">Plans Uploaded</th>
            <th scope="col">Last Upload Date</th>
          </tr>
        </thead>
        <tbody *ngIf="admins && admins.length > 0">
          <tr *ngFor="let admin of admins; index as i" style="background-color: rgba(0, 0, 0, 0.05)">
            <td>
              {{ admin.company }}
            </td>
            <td>{{ admin.details.status }}</td>
            <td>{{ admin.details.uploaded }}</td>
            <td>
              {{
              admin.details.uploadDate !== "/"
              ? (admin.details.uploadDate | date: "short")
              : "/"
              }}
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="!admins || admins.length === 0">
          <tr class="text-center" style="background-color: rgba(0, 0, 0, 0.05)">
            <td colspan="100%">No admins registered to this plan request</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div *ngIf="error" class="alert alert-danger col-12" role="alert">
      {{ error }}
    </div>
  </div>
</div>